import React from "react";
import { graphql } from "gatsby";
import { NodesContext } from "./page";
import type { PageContext, Image } from "./page";
import Layout, { Container, colors } from "./layout";
import ContactForm from "./ContactForm";

type Props = {
  data: {
    datoCmsReference: {
      client: string;
      quote: string;
      nameTitle: string;
      pageContent: string;
      heroImage: null | Image;
      seoMetaTags: { tags: any[] };
    };
    datoCmsReferencePageContactForm: {
      contactForm: [
        {
          title: string;
          description: string;
          includeMessagePart: boolean;
          buttonText: string;
          addMorePadding: boolean;
          thankYouText: null | string;
          subscribeToMailchimpId: null | string;
          subscribedToMailchimpThankYouText: null | string;
        }
      ];
    };
  };
  pageContext: PageContext;
  location: { pathname: string };
};

const Effect: React.FC = () => {
  const divRef = React.useRef<HTMLDivElement>(null!);
  const [size, setSize] = React.useState<
    undefined | { width: number; height: number }
  >(undefined);
  const rects = React.useMemo(() => {
    const range = (a: number, b: number) => Math.random() * (b - a) + a;
    const rects: {
      x: number;
      y: number;
      w: number;
      h: number;
      d: "up" | "down" | "left" | "right";
    }[] = [];
    rects.push({
      x: -0.1,
      y: -0.1,
      w: range(0.9, 1.0),
      h: range(0.9, 1.0),
      d: "left",
    });
    rects.push({
      x: 0.2,
      y: 0.1,
      w: range(0.9, 1.0),
      h: range(0.8, 0.9),
      d: "right",
    });
    return rects;
  }, []);
  React.useLayoutEffect(() => {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        setSize({ width: cr.width, height: cr.height });
      }
    });
    ro.observe(divRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);
  const [sy, setSY] = React.useState(0);
  React.useLayoutEffect(() => {
    const scroll = () => {
      const rect = divRef.current!.getBoundingClientRect();
      let y = 1 - (rect.top + rect.height) / (window.innerHeight + rect.height);
      if (y < 0) y = 0;
      if (y > 1) y = 1;
      setSY(y);
    };
    window.addEventListener("scroll", scroll, false);
    requestAnimationFrame(() => {
      scroll();
    });
    return () => {
      window.removeEventListener("scroll", scroll, false);
    };
  }, [size]);
  return (
    <>
      <div
        ref={divRef}
        css={`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
        `}
      >
        {typeof size !== "undefined" && (
          <svg width={size.width} height={size.height}>
            <g
              fill="none"
              strokeWidth={1 / window.devicePixelRatio}
              stroke="#000"
            >
              {rects.map(({ x, y, w, h, d }) => (
                <>
                  <rect
                    width={w * size.width}
                    height={h * size.height}
                    css={`
                      transition: x 300ms ease-out, y 300ms ease-out;
                    `}
                    x={
                      (x +
                        (d === "left" ? -sy * 0.1 : 0) +
                        (d === "right" ? sy * 0.1 : 0)) *
                      size.width
                    }
                    y={
                      (y +
                        (d === "up" ? -sy * 0.1 : 0) +
                        (d === "down" ? sy * 0.1 : 0)) *
                      size.height
                    }
                    rx={17.4455}
                  />
                </>
              ))}
            </g>
          </svg>
        )}
      </div>
    </>
  );
};

const Reference: React.FC<Props> = ({
  pageContext,
  data: {
    datoCmsReference: {
      heroImage,
      client,
      nameTitle,
      pageContent,
      quote,
      seoMetaTags,
    },
    datoCmsReferencePageContactForm: {
      contactForm: [contactFormProps],
    },
  },
  location: { pathname },
}) => {
  return (
    <>
      <Layout
        pageContext={pageContext}
        pathname={pathname}
        seoMetaTags={seoMetaTags}
      >
        {heroImage !== null && (
          <div
            css={`
              position: relative;
            `}
          >
            <Effect />
            <Container width={934}>
              <div
                css={`
                  padding-top: 132px;
                  padding-bottom: 60px;
                  @media (max-width: 640px) {
                    padding-top: 50px;
                    padding-bottom: 30px;
                  }
                `}
              >
                <div
                  css={`
                    background: linear-gradient(
                        0deg,
                        rgba(255, 206, 46, 0.1),
                        rgba(255, 206, 46, 0.1)
                      ),
                      url(${heroImage.url});
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    aspect-ratio: ${heroImage.width} / ${heroImage.height};
                    border-radius: 17.4455px;
                    @media (max-width: 640px) {
                      aspect-ratio: 301 / 225;
                    }
                    z-index: 2;
                    position: relative;
                  `}
                />
              </div>
            </Container>
          </div>
        )}
        <Container width={610}>
          <h1
            css={`
              ${colors.ag.H1}
              padding-top: 60px;
              margin-bottom: 33px;
              @media (max-width: 640px) {
                padding-top: 30px;
              }
            `}
          >
            {client}
          </h1>
          <div
            css={`
              margin-bottom: 40px;
            `}
          >
            <p
              css={`
                ${colors.ag["Body P Large"]}
                margin-bottom: 1em;
              `}
            >
              “{quote}“
            </p>
            <div
              css={`
                ${colors.ag["Body P"]}
              `}
              dangerouslySetInnerHTML={{ __html: nameTitle }}
            />
          </div>
          <div
            css={`
              ${colors.ag["Body P"]}
              h4 {
                ${colors.ag.H4}
              }
              display: grid;
              grid-gap: 0.75em;
            `}
            dangerouslySetInnerHTML={{ __html: pageContent }}
          />
          <div
            css={`
              height: 120px;
            `}
          />
        </Container>
        <ContactForm {...contactFormProps} />
      </Layout>
    </>
  );
};

const Reference1: React.FC<Props> = props => {
  const { nodes, locale } = props.pageContext;
  return (
    <NodesContext.Provider value={{ nodes, locale }}>
      <Reference {...props} />
    </NodesContext.Provider>
  );
};
export default Reference1;

export const query = graphql`
  query ($originalId: String!, $locale: String!) {
    datoCmsReference(originalId: { eq: $originalId }, locale: { eq: $locale }) {
      client
      quote
      nameTitle
      pageContent
      heroImage {
        url
        width
        height
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsReferencePageContactForm(locale: { eq: $locale }) {
      contactForm {
        title
        description
        includeMessagePart
        buttonText
        addMorePadding
        thankYouText
        subscribeToMailchimpId
        subscribedToMailchimpThankYouText
      }
    }
  }
`;
